import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'src/components/Seo'

import {
  Hero,
  InPageNav,
  Video,
  SalesArguments,
  CareerChances,
  TimeLine,
  ContactPersonTwo,
  OpenPositions,
  EmployeeProfiles,
} from 'src/sections'

const CareerOverview = ({ data, pageContext }) => {
  const page = data.wpPage
  const jobList = pageContext.additionalData
  const hero = page.atspHero
  const intro = page.atspCareerOverviewPage.careerOverviewIntro
  const video = page.atspCareerOverviewPage.careerOverviewVideo
  const benefits = page.atspCareerOverviewPage.careerOverviewBenefits
  const chances = page.atspCareerOverviewPage.careerOverviewChances
  const timeline = page.atspCareerOverviewPage.careerOverviewProcess
  const contact = page.atspCareerOverviewPage.careerOverviewContact
  const open = page.atspCareerOverviewPage.careerOverviewOpen
  const careerRepeater =
    page.atspCareerOverviewPage.careerOverviewCareers.repeater

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <InPageNav
        heading={intro.heading}
        text={intro.text}
        inPageNav={intro.inPageNav}
      />
      <Video
        heading={video.heading}
        text={video.text}
        video={video.video}
        video2={video?.video2}
        videos={video.videos}
        sectionId={video.sectionId}
      />
      <SalesArguments
        heading={benefits.heading}
        intro={benefits.text}
        salesArgumentsRepeater={benefits.repeater}
        sectionId={benefits.sectionId}
        hasBackground
        hasCenter
        hasCardBottomSpacing
      />
      <CareerChances
        heading={chances.heading}
        text={chances.text}
        repeater={chances.repeater}
        sectionId={chances.sectionId}
      />
      <EmployeeProfiles profiles={careerRepeater} />
      <OpenPositions
        heading={open.heading}
        columnHeading1={open.columnHeading1}
        columnHeading2={open.columnHeading2}
        columnHeading3={open.columnHeading3}
        sectionId={open.sectionId}
        jobList={jobList}
      />
      <TimeLine
        heading={timeline.heading}
        intro=""
        items={timeline.timelineRepeater}
        hasReadMore={false}
        sectionId={timeline.sectionId}
      />
      <ContactPersonTwo
        heading={contact.heading}
        contactPersonOne={contact.contactPerson1}
        contactPersonTwo={contact.contactPerson2}
        sectionId={contact.sectionId}
      />
    </>
  )
}

export const pageQuery = graphql`
  query CareerOverviewById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      atspCareerOverviewPage {
        careerOverviewIntro {
          heading
          text
          inPageNav {
            linkTo
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        careerOverviewVideo {
          heading
          text
          videos {
            video
            thumbnail {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          sectionId
        }
        careerOverviewBenefits {
          heading
          text
          sectionId
          repeater {
            heading
            wysiwyg
            icon {
              id
              localFile {
                publicURL
              }
            }
          }
        }
        careerOverviewChances {
          heading
          sectionId
          text
          repeater {
            heading
            wysiwyg
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        careerOverviewProcess {
          heading
          sectionId
          timelineRepeater {
            heading
            text
            button {
              title
              url
              target
            }
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
        careerOverviewContact {
          heading
          sectionId
          contactPerson1 {
            ... on WpKontakt {
              title
              id
              atspcontacts {
                contactData {
                  email
                  extension
                }
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          contactPerson2 {
            ... on WpKontakt {
              title
              id
              atspcontacts {
                contactData {
                  email
                  extension
                }
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        careerOverviewOpen {
          columnHeading1
          columnHeading2
          columnHeading3
          heading
          sectionId
        }
        careerOverviewCareers {
          repeater {
            heading
            interviewHeading
            name
            text
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CareerOverview
